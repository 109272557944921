import {
  CartForm,
  CollectionGrid,
  DividerPhoto,
  Grid,
  GridSize,
  GridColumn,
  Hero,
  ProductHero,
  ReviewWidget,
  Video,
  Marquee,
  ShopSort,
  ShopFilter,
  BlogPostGrid,
  BlogPostHeader,
  BlogPostBody,
  BlogPostReadMore,
  BlogCategoryPostGrid,
  BlogAuthorPostGrid,
  PricingPlans,
  SanityColorCode,
  TeamMembersGrid,
  TechStack,
  PredefinedPageModule,
} from '@data/sanity/schema'
import { SanityBlocks } from './blocks'
import {
  SanityBlogAuthor,
  SanityBlogCategory,
  SanityBlogPostOptions,
  SanityBlogPostWithoutBody,
} from './blog'
import { SanityContentFragment, SanityLink } from './content'
import { SanityImageFragment } from './image'
import { SanityHasSlug } from './page'
import { SanityProductFragment } from './product'
import { HasCollectionStrings, HasReviewsStrings } from './site'
import { SanityMuxVideo, SanityVideo, SanityVimeoVideoMedia } from './video'

export enum SanityModuleType {
  GRID = 'grid',
  HERO = 'hero',
  DIVIDER_PHOTO = 'dividerPhoto',
  PRODUCT_HERO = 'productHero',
  COLLECTION_GRID = 'collectionGrid',
  REVIEW_WIDGET = 'reviewWidget',
  CART_FORM = 'cartForm',
  VIDEO = 'video',
  MARQUEE = 'marquee',
  BLOG_POST_GRID = 'blogPostGrid',
  BLOG_POST_HEADER = 'blogPostHeader',
  BLOG_POST_BODY = 'blogPostBody',
  BLOG_POST_READ_MORE = 'blogPostReadMore',
  BLOG_CATEGORY_POST_GRID = 'blogCategoryPostGrid',
  BLOG_AUTHOR_POST_GRID = 'blogAuthorPostGrid',
  PRICING_PLANS = 'pricingPlans',
  TEAM_MEMBERS_GRID = 'teamMembersGrid',
  TECH_STACK = 'techStack',
  PREDEFINED_PAGE_MODULE = 'predefinedPageModule',
}

// Module component types
export enum SanityColor {
  WHITE = 'white',
  BLACK = 'black',
}

export type SanityGridColumnSize = {
  breakpoint?: string
  width?: number
  justify?: string
  align?: string
  start?: number
}

type SanityGridSpacingValue =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 8
  | 10
  | 12
  | 16
  | 20
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64

export interface SanityGridSpacingFields {
  top?: SanityGridSpacingValue
  right?: SanityGridSpacingValue
  bottom?: SanityGridSpacingValue
  left?: SanityGridSpacingValue
  xsTop?: SanityGridSpacingValue
  xsRight?: SanityGridSpacingValue
  xsBottom?: SanityGridSpacingValue
  xsLeft?: SanityGridSpacingValue
  smTop?: SanityGridSpacingValue
  smRight?: SanityGridSpacingValue
  smBottom?: SanityGridSpacingValue
  smLeft?: SanityGridSpacingValue
  mdTop?: SanityGridSpacingValue
  mdRight?: SanityGridSpacingValue
  mdBottom?: SanityGridSpacingValue
  mdLeft?: SanityGridSpacingValue
  lgTop?: SanityGridSpacingValue
  lgRight?: SanityGridSpacingValue
  lgBottom?: SanityGridSpacingValue
  lgLeft?: SanityGridSpacingValue
  xlTop?: SanityGridSpacingValue
  xlRight?: SanityGridSpacingValue
  xlBottom?: SanityGridSpacingValue
  xlLeft?: SanityGridSpacingValue
}

export interface SanityGridSpacing {
  padding?: SanityGridSpacingFields
  margin?: SanityGridSpacingFields
}

export interface SanityGridColumn {
  _key: string
  sizes: SanityGridColumnSize[]
  blocks: SanityBlocks[]
  spacing?: SanityGridSpacing
}

export type SanityGrid = {
  background?: SanityColor
  columns: SanityGridColumn[]
  spacing?: SanityGridSpacing
  size?: number
  noColumnGaps?: boolean
  noRowGaps?: boolean
  reverseSequence?: boolean
}

export interface SanityHeroPhotos {
  mobilePhoto?: SanityImageFragment
  desktopPhoto?: SanityImageFragment
}

export type SanityHeroVimeoVideo = Pick<SanityVimeoVideoMedia, 'link' | 'name'>

export enum SanityHeroBackgroundType {
  PHOTO = 'photo',
  VIMEO_VIDEO = 'video',
  MUX_VIDEO = 'mux-video',
}

export enum SanityHeroContentPosition {
  CENTER = 'center',
  BOTTOM_LEFT = 'bottom-left',
}

export interface SanityHero {
  bgType: SanityHeroBackgroundType
  contentPosition: SanityHeroContentPosition
  content?: SanityContentFragment[]
  photos?: SanityHeroPhotos
  vimeoVideo?: SanityHeroVimeoVideo
  muxVideo?: SanityMuxVideo
}

export interface SanityDividerPhoto {
  photo: SanityImageFragment
}

export enum SanityFilterType {
  SIMPLE = ' ',
  SWATCH = 'swatch',
}

export enum SanityFilterGroupDisplay {
  DEFAULT = ' ',
  GRID = 'grid',
}

export type SanityFilterGroupOption = SanityHasSlug & {
  type: SanityFilterType
  title: string
  color?: SanityColorCode
}

export type SanityFilterGroup = SanityHasSlug & {
  _key: string
  display: SanityFilterGroupDisplay
  title: string
  options: SanityFilterGroupOption[]
}

export type SanityFilter = Pick<ShopFilter, 'isActive'> & {
  groups?: SanityFilterGroup[]
}

export enum SanitySortOptionType {
  FEATURED = 'featured',
  ALPHA_ASCENDING = 'alphaAsc',
  ALPHA_DESCENDING = 'alphaDesc',
  DATE_ASCENDING = 'dateAsc',
  DATE_DESCENDING = 'dateDesc',
  PRICE_ASCENDING = 'priceAsc',
  PRICE_DESCENDING = 'priceDesc',
}

export type SanitySortOption = {
  type: SanitySortOptionType
  title: string
}

export type SanitySort = Pick<ShopSort, 'isActive'> & {
  options?: SanitySortOption[]
}

export type SanityCollectionGrid = Pick<CollectionGrid, 'active'> & {
  paginationLimit: number
  title?: string
  filter?: SanityFilter
  sort?: SanitySort
}

export enum SanityReviewWidgetType {
  MAIN = 'main',
  PRODUCT = 'product',
}

export type SanityReviewWidget = {
  type: SanityReviewWidgetType
}

export type SanityCartForm = Pick<CartForm, 'active'>

export type SanityMarqueeSimpleItem = {
  _type: 'simple'
  text: string
}

export type SanityMarqueePhotoItem = {
  _type: 'photo'
  photo: SanityImageFragment
}

export type SanityMarqueeProductItem = {
  _type: 'product'
  _id: string
  product: SanityProductFragment
}

export type SanityMarqueeItem =
  | (SanityMarqueeSimpleItem & {
      _key: string
    })
  | (SanityMarqueePhotoItem & {
      _key: string
    })
  | (SanityMarqueeProductItem & {
      _key: string
    })

export type SanityMarquee = Pick<
  Marquee,
  'speed' | 'maxItemWidth' | 'reverse' | 'pausable'
> & {
  items: SanityMarqueeItem[]
  content?: SanityContentFragment[]
  contentAlignment?: 'left' | 'center' | 'right'
}

export interface SanityBlogPostGrid {
  posts: SanityBlogPostWithoutBody[]
  author?: SanityBlogAuthor
  category?: SanityBlogCategory
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostHeader {
  post?: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostBody {
  content: SanityContentFragment
}

export interface SanityBlogPostReadMore {
  posts: SanityBlogPostWithoutBody[]
}

export type SanityBlogCategoryPostGrid = Pick<SanityBlogPostGrid, 'options'> & {
  posts: SanityBlogPostWithoutBody[]
}

export type SanityBlogAuthorPostGrid = Pick<SanityBlogPostGrid, 'options'> & {
  posts: SanityBlogPostWithoutBody[]
}

export interface SanityPricingPlan {
  name?: string
  price?: string
  description?: string
  featured?: boolean
  features?: string
  button?: { label?: string } & SanityLink
}

export interface SanityPricingPlans {
  plans: SanityPricingPlan[]
}

export interface SanityTeamMembersGrid {
  title?: string
  subtitle?: string
  teamMembers?: {
    name: string
    slug: string
    jobTitle?: string
    about?: string
    linkedInUrl?: string
    blogAuthor?: {
      slug: string
    }
    photo?: SanityImageFragment
  }[]
}

export type SanityTechStackItem = SanityImageFragment

interface SanityTechStackRow {
  _key: string
  heading: string
  subheading?: string
  items: SanityTechStackItem[]
}

export interface SanityTechStack {
  heading?: string
  content?: SanityContentFragment
  rows: SanityTechStackRow[]
}

// Module raw data types
export type SanityRawModule =
  | Grid
  | Hero
  | DividerPhoto
  | ProductHero
  | CollectionGrid
  | ReviewWidget
  | CartForm
  | Video
  | Marquee
  | BlogPostGrid
  | BlogPostHeader
  | BlogPostBody
  | BlogPostReadMore
  | PricingPlans

// Module data types
export type SanityGridModule = Pick<Grid, '_type'> &
  SanityGrid & {
    _key: string
  }

export type SanityHeroModule = Pick<Hero, '_type'> &
  SanityHero & {
    _key: string
  }

export type SanityDividerPhotoModule = Pick<DividerPhoto, '_type'> &
  SanityDividerPhoto & {
    _key: string
  }

export type SanityProductHeroModule = Pick<ProductHero, '_type'> & {
  _key: string
}

export type SanityCollectionGridModule = Pick<CollectionGrid, '_type'> &
  SanityCollectionGrid &
  HasCollectionStrings & {
    _key: string
  }

export type SanityReviewWidgetModule = Pick<ReviewWidget, '_type'> &
  SanityReviewWidget &
  HasReviewsStrings & {
    _key: string
  }

export type SanityCartFormModule = Pick<CartForm, '_type'> &
  SanityCartForm & {
    _key: string
  }

export type SanityVideoModule = Pick<Video, '_type'> &
  SanityVideo & {
    _key: string
  }

export type SanityMarqueeModule = Pick<Marquee, '_type'> &
  SanityMarquee & {
    _key: string
  }

export type SanityBlogPostGridModule = Pick<BlogPostGrid, '_type'> &
  SanityBlogPostGrid & {
    _key: string
  }

export type SanityBlogPostHeaderModule = Pick<BlogPostHeader, '_type'> &
  SanityBlogPostHeader & {
    _key: string
  }

export type SanityBlogPostBodyModule = Pick<BlogPostBody, '_type'> &
  SanityBlogPostBody & {
    _key: string
  }

export type SanityBlogPostReadMoreModule = Pick<BlogPostReadMore, '_type'> &
  SanityBlogPostReadMore & {
    _key: string
  }

export type SanityBlogCategoryPostGridModule = Pick<
  BlogCategoryPostGrid,
  '_type'
> &
  SanityBlogCategoryPostGrid & {
    _key: string
  }

export type SanityBlogAuthorPostGridModule = Pick<BlogAuthorPostGrid, '_type'> &
  SanityBlogAuthorPostGrid & {
    _key: string
  }

export type SanityPricingPlansModule = Pick<PricingPlans, '_type'> &
  SanityPricingPlans & {
    _key: string
  }

export type SanityTeamMembersGridModule = Pick<TeamMembersGrid, '_type'> &
  SanityTeamMembersGrid & {
    _key: string
  }

export type SanityTechStackModule = Pick<TechStack, '_type'> &
  SanityTechStack & {
    _key: string
  }

type SanityBaseModule =
  | SanityGridModule
  | SanityHeroModule
  | SanityDividerPhotoModule
  | SanityProductHeroModule
  | SanityCollectionGridModule
  | SanityReviewWidgetModule
  | SanityCartFormModule
  | SanityVideoModule
  | SanityMarqueeModule
  | SanityBlogPostGridModule
  | SanityBlogPostHeaderModule
  | SanityBlogPostBodyModule
  | SanityBlogPostReadMoreModule
  | SanityBlogCategoryPostGridModule
  | SanityBlogAuthorPostGridModule
  | SanityPricingPlansModule
  | SanityTeamMembersGridModule
  | SanityTechStackModule

interface SanityPredefinedPageModule {
  pageModules: SanityBaseModule[]
}

export type SanityPredefinedPageModuleModule = Pick<
  PredefinedPageModule,
  '_type'
> &
  SanityPredefinedPageModule & {
    _key: string
  }

export type SanityModule = SanityBaseModule | SanityPredefinedPageModuleModule
